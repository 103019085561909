import Meta from '../components/Meta'
import logo from '../assets/logo-homepage.png';

import badge1 from '../assets/badge1.png';
import badge2 from '../assets/badge2.png';
import badge3 from '../assets/badge3.png';
import badge4 from '../assets/badge4.png';

import { Container, Row, Col } from "react-bootstrap";

const Home = () => {

  const pageTitle = 'B3AR MARKET 2022'

  return (
    <div>
      <Meta title={pageTitle}/>
      <div id="banner" className="d-flex align-items-center justify-content-center"><img src={logo} className="img-fluid" alt={pageTitle}/></div>

      <Container>
        <h1 className='text-center mb-5'>Why ?</h1>
        <p className='text-center'>It's been a few months now that this road down to hell has begun. The famous Bear market that so many people predicted has arrived ! The question now is how far will it take us ? It is in this period of fear and doubt that NFT B3AR MARKET is coming. But these are not just another random bears without meaning or history.</p>
        <p className='text-center'>Each of these NFTs will be a unique witness to the bear market of 2022. By minting one of these bears, you become the owner of a piece of history, for having been in the middle of the storm and not letting yourself be knocked down (you will have unlocked an accomplishment of your crypto life).</p>
        <p className='text-center'>Each of these NFTs is represented by an outrageous, mocking, arrogant bear, which feeds on our misfortune, and reminds us through its characteristics of the main events of this bear market (the fall of Luna, Celsius, Solend...). His banner makes it official that you are still here, and that you are one of the most resilient elements of the Cryptoworld."</p>

        <div className="the-team d-flex align-items-center justify-content-center my-5"><h1>The Team</h1></div>

        <Row>
          <Col lg={4} className="text-center mb-5"><div className="team-member-bg tm-1 m-auto"></div><h1 className="team-member-name">BEAR MARKET</h1>Founder</Col>
          <Col lg={4} className="text-center mb-5"><div className="team-member-bg tm-2 m-auto"></div><h1 className="team-member-name">Funny Bear</h1>Coordinator</Col>
          <Col lg={4} className="text-center mb-5"><div className="team-member-bg tm-3 m-auto"></div><h1 className="team-member-name">LoKo B3aR</h1>Designer - Illustrator</Col>
          <Col lg={4} className="text-center mb-5"><div className="team-member-bg tm-4 m-auto"></div><h1 className="team-member-name">Nekr</h1>Smart Contract Dev</Col>
          <Col lg={4} className="text-center mb-5"><div className="team-member-bg tm-5 m-auto"></div><h1 className="team-member-name">Bear Trainer</h1>DApp Dev - NFT Generator</Col>
          <Col lg={4} className="text-center mb-5"><div className="team-member-bg tm-6 m-auto"></div><h1 className="team-member-name">Angry Bear</h1>Team Manager</Col>
        </Row>        
      </Container>
      <Container fluid className="eyes">
        <Container>
          <h1 className="text-center mt-5 mb-3">Bear Mentality</h1>
          <Row>
            <Col lg={{ span: 6, offset: 3 }} className="spec mb-4">Unique remember</Col>
            <Col lg={{ span: 6, offset: 3 }} className="spec mb-4">Supply of 222</Col>
            <Col lg={{ span: 6, offset: 3 }} className="spec mb-4">Low supply / Low price / High value</Col>
            <Col lg={{ span: 6, offset: 3 }} className="spec mb-4">Ethereum</Col>
            <Col lg={{ span: 6, offset: 3 }} className="spec mb-4">WL have priority minting benefits</Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="badges">
        <Container>
          <h1 className="text-center mt-5 mb-3">Badges</h1>
          <h2 className="text-center my-5">WHAT RANK<br />ARE YOU?</h2>
          <Row>
            <Col xs={6} lg={3} className="text-center mb-1"><img src={badge1} className="img-fluid"/></Col>
            <Col xs={6} lg={3} className="text-center mb-1"><img src={badge2} className="img-fluid"/></Col>
            <Col xs={6} lg={3} className="text-center mb-1"><img src={badge3} className="img-fluid"/></Col>
            <Col xs={6} lg={3} className="text-center mb-1"><img src={badge4} className="img-fluid"/></Col>
          </Row>

        <p className='text-center mt-4'>That extra badge on your NFT further certifies your presence as well as your state of mind. Are you fully exposed or are you buying all the dips.</p>

        </Container>
      </Container>
    </div>
  )
}

export default Home