import logo from '../assets/logo-footer.png';

import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <>
      <footer className='text-center text-capitalize mt-auto'>
      <Container fluid>
        <Row>
          <Col lg={{ span: 6, offset: 3 }} className="mb-4">

          <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 bm-sn">
            <li>
              <a href="https://twitter.com/B3arMarket/" target="_blank" rel="noreferrer" className="px-2">
                <div className="sn-icon twitter-icon"></div>
              </a>
            </li>
            <li>
              <a href="https://discord.gg/usK7k3EByh" target="_blank" rel="noreferrer" className="px-2">
              <div className="sn-icon discord-icon"></div>
              </a>
            </li>
            <li>
              <a href="https://opensea.io/collection/b3ar-market" target="_blank" rel="noreferrer" className="px-2">
              <div className="sn-icon opensea-icon"></div>
              </a>
            </li>
          </ul>

          <h3>Rrrrrekt!</h3>
          Copyright B3AR MARKET &copy; {year}

          </Col>
          <Col lg={3} className="mt-4 d-none d-lg-block"><img src={logo}/></Col>

        </Row> 
        </Container>
        
      </footer>
    </>
  )
}

export default Footer