import {useEffect, useState} from 'react';
import {ethers} from 'ethers';
import contract from '../contracts/B3ARMARKETisERC721A.json';
import {Container, Row, Col} from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';

import og from '../assets/list/og.json';
import wl from '../assets/list/whitelist.json';
import fm from '../assets/list/fm.json';

import logo from '../assets/logo.png';

const {MerkleTree} = require('merkletreejs');
const keccak256 = require('keccak256');
window.Buffer = window.Buffer || require('buffer').Buffer;

const contractAddress = "0x2098C186D7bfd9d8161E3f60945241f605Eeb2e2";
const abi = contract.abi;

const pageTitle = 'B3AR MARKET 2022'
const maxPublicSupply = 192;
const maxSupply = 222;

let ogTree;
let wlTree;
let fmTree;

function Mint() {

    const [mintNumber, setMintNumber] = useState(1);
    const [maxMintNumber, setMaxMintNumber] = useState(2);
    const [isUserOG, setIsUserOG] = useState(false);
    const [isUserWL, setIsUserWL] = useState(false);
    const [isUserFM, setIsUserFM] = useState(false);
    const [mintedOG, setMintedOG] = useState(0);
    const [mintedWL, setMintedWL] = useState(0);
    const [mintedFM, setMintedFM] = useState(0);
    const [OGProof, setOGProof] = useState([]);
    const [WLProof, setWLProof] = useState([]);
    const [FMProof, setFMProof] = useState([]);
    const mintMore = () => {
        if(maxMintNumber >= maxPublicSupply - amountMinted) { setMaxMintNumber(maxPublicSupply - amountMinted); }
        setMintNumber(c => Math.min(c + 1, maxMintNumber));
    };

    const mintLess = () => {
        if (mintNumber > 1) {
            setMintNumber(mintNumber-1);
        }
    };

    const [currentStep, setCurrentStep] = useState(0);
    const [currentAccount, setCurrentAccount] = useState(null)
    const [currentAccountName, setCurrentAccountName] = useState(null)
    const [correctNetwork, setCorrectNetwork] = useState(false)
    const [mintStep, setMintStep] = useState(0)
    const [mintedNFT, setMintedNFT] = useState(null)

    const [amountMinted, setAmountMinted] = useState(0);

    const checkWalletIsConnected = async () => {
        const {ethereum} = window;
        if (ethereum) {

            const accounts = await ethereum.request({method: 'eth_accounts'});
            if (accounts.length !== 0) {
                const account = accounts[0];
                console.log("Authorized account : ", account);
                setCurrentAccount(account);                
            } else {
                setCurrentAccount(null);
                console.log("No authorized account");
            }
        }
    }

    const getAccountName = async () => {
        const {ethereum} = window;
        if (ethereum) {
            ethereum.request({method: 'eth_chainId'}).then(function(chainId) {
                if (currentAccount != null) {
                try {
                        const provider = new ethers.providers.Web3Provider(ethereum);
                        provider.lookupAddress(currentAccount).then(function(name) { if (name) { setCurrentAccountName(name) } else { setCurrentAccountName(null) }; });
                } catch (error) { console.log(error); }
                }
            })
        }
    }

    const checkCorrectNetwork = async () => {
        const {ethereum} = window
        if (ethereum) {
            let chainId = await ethereum.request({method: 'eth_chainId'})
            console.log('Connected to chain:' + chainId)

            const contractChainId = '0x1' // 0x1 pour Mainnet & 0x4 pour Rinkeby

            if (chainId !== contractChainId) {
                setCorrectNetwork(false)
            } else {
                setCorrectNetwork(true)
            }
        }
    }

    const connectWalletHandler = async () => {
        const {ethereum} = window;
        if (!ethereum) {
            alert("You need Metamask !");
        }

        try {
            const accounts = await ethereum.request({method: 'eth_requestAccounts'});
            //console.log("Found MM account : ", accounts[0]);
            setCurrentAccount(accounts[0]);
        } catch (err) {
            console.log(err)
        }
    }

    const getUserRoles = async () => {

        alreadyMinted().then(() => {
            let ogTab = [];
            let wlTab = [];
            let fmTab = [];

            og.map(a => (
                ogTab.push(a.address)
            ));

            wl.map(a => (
                wlTab.push(a.address)
            ));

            fm.map(a => (
                fmTab.push(a.address)
            ));

            const ogLeaves = ogTab.map(address => keccak256(address));
            const wlLeaves = wlTab.map(address => keccak256(address));
            const fmLeaves = fmTab.map(address => keccak256(address));

            ogTree = new MerkleTree(ogLeaves, keccak256, {sort: true});
            wlTree = new MerkleTree(wlLeaves, keccak256, {sort: true});
            fmTree = new MerkleTree(fmLeaves, keccak256, {sort: true});

            setOGProof(ogTree.getHexProof(keccak256(currentAccount)));
            setWLProof(wlTree.getHexProof(keccak256(currentAccount)));
            setFMProof(fmTree.getHexProof(keccak256(currentAccount)));
        });
    }

    const isOG = async (ogProof) => {
        try {
            const {ethereum} = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner(contractAddress);
                const nftContract = new ethers.Contract(contractAddress, abi, signer);

                const isOG = await nftContract.isOG(currentAccount, ogProof);
                setIsUserOG(isOG);
            } else {
                console.log("No Eth object found");
            }
        } catch (err) {
            console.log(err);
        }
    }

    const alreadyMinted = async () => {
            try {
                const {ethereum} = window;

                if (ethereum) {
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = provider.getSigner(contractAddress);
                    const nftContract = new ethers.Contract(contractAddress, abi, signer);
                    const alreadyOG = await nftContract.mintByWalletOG(currentAccount);
                    setMintedOG(alreadyOG.toNumber());

                    const alreadyWL = await nftContract.mintByWalletWL(currentAccount);
                    setMintedWL(alreadyWL.toNumber());

                    const alreadyFM = await nftContract.mintByWalletFM(currentAccount);
                    setMintedFM(alreadyFM.toNumber());
                } else {
                    console.log("No Eth object found");
                }
            } catch (err) {
                console.log(err);
            }
    }

    const isWL = async (wlProof) => {
        try {
            const {ethereum} = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner(contractAddress);
                const nftContract = new ethers.Contract(contractAddress, abi, signer);

                const isWL = await nftContract.isWhitelisted(currentAccount, wlProof);
                setIsUserWL(isWL);
            } else {
                console.log("No Eth object found");
            }
        } catch (err) {
            console.log(err);
        }
    }

    const isFM = async (fmProof) => {
        try {
            const {ethereum} = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner(contractAddress);
                const nftContract = new ethers.Contract(contractAddress, abi, signer);

                const isFM = await nftContract.isFreeMint(currentAccount, fmProof);
                setIsUserFM(isFM);
            } else {
                console.log("No Eth object found");
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getTotalMintedNFT = async () => {

        try {
            const {ethereum} = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner(contractAddress);
                const nftContract = new ethers.Contract(contractAddress, abi, signer);

                const amount = await nftContract.totalSupply();
                setAmountMinted(parseInt(amount));

            } else {
                console.log("No Eth object found");
            }

        } catch (err) {
            console.log(err);
        }

    }

    const getCurrentStep = async () => {
      try {
        const {ethereum} = window;

        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner(contractAddress);
          const nftContract = new ethers.Contract(contractAddress, abi, signer);

          const amount = await nftContract.currentStep();
          setCurrentStep(parseInt(amount));
        } else {
          console.log("No Eth object found");
        }

      } catch (err) {
        console.log(err);
      }
    }

    const onUpdatedStep = async () => {
        try {
            const {ethereum} = window;
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner(contractAddress);
                const nftContract = new ethers.Contract(contractAddress, abi, signer);

                nftContract.on("stepUpdated", (step) => {
                    console.log("UpdatedStep : ", step);
                    setMintStep(0);
                    setCurrentStep(parseInt(step));
                });
            } else {
                console.log("No Eth object found");
            }

        } catch (err) {
            console.log(err);
        }
    }

    const onNewMint = async () => {
        try {
            const {ethereum} = window;
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner(contractAddress);
                const nftContract = new ethers.Contract(contractAddress, abi, signer);

                nftContract.on("newMint", (owner, startId, number) => {
                    getTotalMintedNFT();
                });
            } else {
                console.log("No Eth object found");
            }

        } catch (err) {
            console.log(err);
        }
    }

    const mintNftHandler = async () => {
        try {
            const {ethereum} = window;
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const nftContract = new ethers.Contract(contractAddress, abi, signer);
                setMintStep(1);
                let nftTxn;
                let price = await nftContract.getPrice();
                if (currentStep === 1) {
                    nftTxn = await nftContract.OGMint(OGProof, {value: price});
                } else if (currentStep === 2) {
                    price = price.mul(mintNumber);
                    nftTxn = await nftContract.WLMint(WLProof, mintNumber, {value: price});
                } else if (currentStep === 3) {
                    price = price.mul(mintNumber);
                    nftTxn = await nftContract.PublicMint(mintNumber, {value: price});
                } else {
                    nftTxn = await nftContract.FreeMint(FMProof);
                }

                console.log("Minting...");
                setMintStep(2);
                await nftTxn.wait();

                console.log(`Minted ! ${nftTxn.hash}`);
                setMintStep(3);
                setMintedNFT(nftTxn.hash);
                console.log(nftTxn);
            } else {
                console.log("No Eth object found");
            }
        } catch (err) {
            setMintStep(0);
            console.log(err);
            if (err.code === 'INSUFFICIENT_FUNDS') { toast.error("Insufficient funds to mint " + mintNumber + " bears"); }
        }
    }

    const connectWalletButton = () => {
        return (
            <div className="text-center my-5">
                <p className="connect-wallet">.: Connect your ETH wallet :.</p>
                <button onClick={connectWalletHandler} className='red-button'>Connect Wallet</button>
            </div>
        )
    }

    const mintUI = () => {
        return (
            <div className="text-center my-5">
                <div className="text-center my-5">
                    <h1 className="minted-counter mb-5">{amountMinted} / 222 minted</h1>
                </div>
                <p className="contract-address mb-0">.: Smart Contract : <a
                    href={`https://etherscan.io/address/${contractAddress}`} target="_blank"
                    rel="noreferrer">{contractAddress}</a> :.</p>
                <p className="client-address mb-5">
                    .: Your wallet address : <a
                    href={`https://etherscan.io/address/${currentAccount}`} target="_blank"
                    rel={"noreferrer"}>{ currentAccountName != null ?
                        (
                            <span>{currentAccountName} ~ {currentAccount}</span>
                        )
                    :
                        <span>{currentAccount}</span> }
                    </a> :.
                </p>

                {
                    !correctNetwork ?
                        (
                            <p className="error">Please switch to Ethereum network !</p>
                        )
                    :
                        currentStep <= 0 && mintStep === 0 ?
                            <>
                                <div className="mt-3"><h2>The mint hasn't started yet !</h2></div>
                                <div className={`mint-box mb-step-0 mb-error`}>
                                    <button disabled className='red-button'>Wait...</button>
                                </div>
                            </>
                            :
                            ((amountMinted === maxSupply) || (amountMinted >= maxPublicSupply && currentStep === 3) || (currentStep === 5)) && mintStep >= 0 ?
                                <div className="mint-box mb-sold-out"/>
                                :
                                currentStep === 1 && mintStep === 0 ?
                                    <>
                                        {
                                            isUserOG ?
                                                <>
                                                    <div className="mt-3"><h2>Sale for OGs</h2></div>
                                                        { mintedOG === 1 ?
                                                            <div className={`mint-box mb-step-0 mb-error`}>
                                                            <button disabled className='red-button'>Already Minted</button>
                                                            </div>
                                                            :
                                                            <div className={`mint-box mb-step-0`}>
                                                            <button onClick={() => {mintNftHandler().then()}} className='red-button'>Mint 1 NFT</button>
                                                            </div>
                                                        }
                                                </>
                                                :
                                                <>
                                                    <div className="mt-3"><h2>Wait for whitelist or public sale...</h2></div>
                                                    <div className={`mint-box mb-step-0 mb-error`}>
                                                        <button disabled className='red-button'>You are not OG !</button>
                                                    </div>
                                                </>
                                        }

                                    </>
                                    :
                                    currentStep === 2 && mintStep === 0 ?
                                        <>
                                            {
                                                isUserWL ?
                                                    <>
                                                        <div className="mt-3"><h2>Sale for WLs</h2></div>
                                                        {
                                                            mintedWL !== 2 ?
                                                                <>
                                                                    <div className={`mint-box mb-step-0`}>
                                                                        <button onClick={() => {mintNftHandler().then()}} className='red-button'>Mint {mintNumber} NFT</button>
                                                                    </div>
                                                                    <div className="mt-3"><h2>How many bears do you want ? {2 - mintedWL} max remaining.</h2>
                                                                        <button className="small-red-button" onClick={mintLess}>-</button>
                                                                        <button className="small-red-button" onClick={mintMore}>+</button>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className={`mint-box mb-step-0 mb-error`}>
                                                                        <button disabled className='red-button'>Already Minted</button>
                                                                    </div>
                                                                </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <div className="mt-3"><h2>Wait for public sale...</h2></div>
                                                        <div className={`mint-box mb-step-0 mb-error`}>
                                                            <button disabled className='red-button'>You are not WL !</button>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                        :
                                        currentStep === 3  && mintStep === 0 ?
                                            <>
                                                <div className="mt-3"><h2>Public sale</h2></div>
                                                <div className={`mint-box mb-step-0`}>
                                                    <button onClick={() => {mintNftHandler().then()}} className='red-button'>Mint {mintNumber} NFT</button>
                                                </div>
                                                <div className="mt-3"><h2>How many bears do you want ?</h2>
                                                    <button className="small-red-button" onClick={mintLess}>-</button>
                                                    <button className="small-red-button" onClick={mintMore}>+</button>
                                                </div>
                                            </>
                                            :
                                            mintStep === 0 ?
                                            <>
                                                {
                                                    isUserFM ?
                                                        <>
                                                            {
                                                                mintedFM !== 1 ?
                                                                    <>
                                                                        <div className="mt-3"><h2>Free mint time !</h2></div>
                                                                        <div className={`mint-box mb-step-0`}>
                                                                            <button onClick={() => {mintNftHandler().then()}} className='red-button'>Mint 1 NFT</button>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className={`mint-box mb-step-0 mb-error`}>
                                                                            <button disabled className='red-button'>Already Minted</button>
                                                                        </div>
                                                                    </>
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                            <div className="mt-3"><h2>You can buy on the secondary market</h2></div>
                                                            <div className={`mint-box mb-step-0 mb-error`}>
                                                                <button disabled className='red-button lt'>You don't have a free mint !</button>
                                                            </div>
                                                        </>
                                                }
                                            </>
                                                :
                                                <></>
                }

                {correctNetwork === true && amountMinted < maxSupply  && mintStep !== 0? (
                    <div className={`mint-box mb-step-${mintStep}`}>
                        {mintStep === 1 ? (<button className='red-button'>Open Metamask...</button>) : null}
                        {mintStep === 2 ? (<button className='red-button'>Mint in progress...</button>) : null}
                        {mintStep === 3 ? (<button className='red-button'>Minting done !</button>) : null}
                    </div>) : null}

                {mintStep === 3 ? (
                    <div><a href={`https://etherscan.io/tx/${mintedNFT}`} target="_blank" rel="noreferrer">.:
                        Minting done, click to see the transaction :.</a></div>) : null}
            </div>
        )
    }

    useEffect(() => {

        checkWalletIsConnected().then();
        checkCorrectNetwork().then();

        if (window.ethereum) {
            window.ethereum.on('chainChanged', () => { checkCorrectNetwork().then(); });
            window.ethereum.on('accountsChanged', () => { checkWalletIsConnected().then(); })
        }

    }, [])

    useEffect(() => {
        getAccountName().then();
    }, [currentAccount, correctNetwork])

    useEffect(() => {
        if(currentAccount != null && correctNetwork === true) {
            getUserRoles().then();
            isOG(OGProof).then();
            isWL(WLProof).then();
            isFM(FMProof).then();
        }
    }, [currentAccount, correctNetwork, OGProof, WLProof, FMProof])

    useEffect(() => {
        if(currentAccount != null && correctNetwork === true) {
            onUpdatedStep().then();
            onNewMint().then();
            getTotalMintedNFT().then();
            getCurrentStep().then();
        }
    }, [currentAccount, correctNetwork])

    useEffect(() => {

        switch (currentStep) {
            case 0:
                setMintNumber(0);
                setMaxMintNumber(0);
                break;
            case 1:
                setMintNumber(1);
                setMaxMintNumber(1);
                break;
            case 2:
                if (mintedWL === 1) {
                    setMintNumber(1);
                    setMaxMintNumber(1);
                } else {
                    setMintNumber(2);
                    setMaxMintNumber(2);
                }
                break;
            case 3:
                setMintNumber(1);
                setMaxMintNumber(10);
                break;
            case 4:
                setMaxMintNumber(1);
                break;
            default:
                setMintNumber(0);
                setMaxMintNumber(0);
                break;
        }
    }, [currentStep])

    return (
        <Container className="mb-5">
            <div><Toaster position="top-right" reverseOrder={false}/></div>
            <div className="text-center"><img src={logo} className="img-fluid pb-3" alt={pageTitle}/></div>
            <p className="text-center">Each of these NFTs will be a unique witness to the B3AR MARKET of 2022. By minting one of these bears, you become the owner of a piece of history, for having been in the middle of the storm and not letting yourself be knocked down. You will have unlocked an accomplishment of your crypto life! This NFT is represented by an outrageous, mocking, arrogant bear, which feeds on our misfortune, and reminds us through its characteristics of the main events of this bear market (the fall of Luna, Celsius, Solend...). His banner makes it official that you are still here, and that you are one of the most resilient elements of the Cryptoworld.
                </p>

            <div>
                {currentAccount ?
                    mintUI() :
                    connectWalletButton()}
            </div>

            <Row>
                <Col lg={4} className="text-center mb-5">
                    <div className="mint-bear-bg mint-bear-1 m-auto"/>
                </Col>
                <Col lg={4} className="text-center mb-5">
                    <div className="mint-bear-bg mint-bear-2 m-auto"/>
                </Col>
                <Col lg={4} className="text-center mb-5">
                    <div className="mint-bear-bg mint-bear-3 m-auto"/>
                </Col>
            </Row>

        </Container>
    )
}

export default Mint;
