import { Container } from "react-bootstrap";
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';

import Layout from "./layout/Layout";

import Home from "./pages/Home";
import Mint from "./pages/Mint";
import Error from "./pages/Error";

import './styles/App.css';

const App = () => {
  return (
    <Router>
      <Layout>
        <Container fluid>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/Mint" element={<Mint />} />
            <Route element={<Error />} />
          </Routes>
        </Container>
      </Layout>
    </Router>
  );
};

export default App;